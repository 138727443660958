import { createSelector } from "reselect";
import { tFooterData, tMenu, tSocials } from "../types";

const getState = (state) => state.footerReducer.footer;

const footerSelector = createSelector([getState], (item) => {

    let results: tFooterData;

    if (item) {
        let menu: tMenu[] = [];
        let socials: tSocials[] = [];

        const { attributes } = item;

        if (attributes && attributes.Menu) {
            if (attributes.Menu && attributes.Menu.length > 0) {
                attributes.Menu.forEach(menuItem => {
                    menu = [
                        ...menu,
                        {
                            id: menuItem.id,
                            title: menuItem.Title,
                            url: menuItem.Url,
                            type: (menuItem.Url.indexOf("https://") > -1 || menuItem.Url.indexOf("http://") > -1) && "outer" || "inner",
                        }
                    ];
                });
            }
        }

        if (attributes && attributes.Socials) {
            if (attributes.Socials && attributes.Socials.length > 0) {
                attributes.Socials.forEach(soc=> {
                    socials = [
                        ...socials,
                        {
                            id: soc.id,
                            icon: soc.Icon && soc.Icon.data && soc.Icon.data.attributes && soc.Icon.data.attributes.url && soc.Icon.data.attributes.url,
                            url: soc.Url,
                        }
                    ];
                });
            }
        }

        results = {
            id: item.id,
            logo: attributes && attributes.Logo && attributes.Logo.data && attributes.Logo.data.attributes && attributes.Logo.data.attributes.url && attributes.Logo.data.attributes.url,
            description: attributes && attributes.Description,
            address: {
                title: attributes && attributes.Address && attributes.Address.Title && attributes.Address.Title,
                description: attributes && attributes.Address && attributes.Address.Description && attributes.Address.Description,
            },
            phone: {
                title: attributes && attributes.Phone && attributes.Phone.Title && attributes.Phone.Title,
                description: attributes && attributes.Phone && attributes.Phone.Description && attributes.Phone.Description,
            },
            email: {
                title: attributes && attributes.Email && attributes.Email.Title && attributes.Email.Title,
                description: attributes && attributes.Email && attributes.Email.Description && attributes.Email.Description,
            },
            copyright: attributes && attributes.Copyright,
            menu,
            socials,
            subscribe: attributes && attributes.SubscribeForm,
        }
    }

    return results;
});

export default footerSelector;
